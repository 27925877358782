<template>
  <div>
    <v-text-field
      outlined
      :value="value.zipCode"
      label="CEP"
      :loading="loadingZipcode"
      @input="(input) => changeProperty(input, 'zipCode')"
    />
    <v-text-field
      ref="inputStreet"
      outlined
      :value="value.street"
      label="Endereço completo"
      :loading="loadingZipcode"
      hint="Incluindo número da residência"
      persistent-hint
      @input="(input) => changeProperty(input, 'street')"
    />
    <v-text-field
      outlined
      :value="value.neighborhood"
      label="Bairro"
      :loading="loadingZipcode"
      @input="(input) => changeProperty(input, 'neighborhood')"
    />
    <v-text-field
      outlined
      :value="value.additional"
      label="Complemento"
      :loading="loadingZipcode"
      @input="(input) => changeProperty(input, 'additional')"
    />
    <v-text-field
      outlined
      :value="value.city"
      label="Cidade *"
      :rules="[$rules.required]"
      :loading="loadingZipcode"
      @input="(input) => changeProperty(input, 'city')"
    />
    <v-text-field
      outlined
      :value="value.state"
      label="UF (Estado) *"
      :rules="[$rules.required]"
      :loading="loadingZipcode"
      maxlength="2"
      @input="(input) => changeProperty(input, 'state')"
    />
    <v-text-field
      outlined
      :value="value.country"
      label="País *"
      :rules="[$rules.required]"
      :loading="loadingZipcode"
      @input="(input) => changeProperty(input, 'country')"
    />
  </div>
</template>

<script>
import cep from "cep-promise";
export default {
  name: "AddressForm",
  props: {
    value: {
      type: Object,
      default: () => ({
        zipCode: "",
        street: "",
        neighborhood: "",
        additional: "",
        city: "",
        state: "",
        country: "",
      }),
    },
  },
  data: () => ({
    loadingZipcode: false,
  }),
  methods: {
    changeProperty(input, property) {
      if (property === "zipCode") {
        this.loadAddressByZipcode(input);
      }
      this.$emit("input", Object.assign(this.value, { [property]: input }));
    },
    async loadAddressByZipcode(zipCode) {
      if (zipCode.length === 8) {
        try {
          this.loadingZipcode = true;
          const { city, neighborhood, state, street } = await cep(zipCode);
          Object.assign(this.value, {
            city,
            neighborhood,
            state,
            street,
            country: "Brasil",
          });
          this.$toasted.global.info({
            message: "CEP encontrado. Favor complete seu endereço.",
          });
          this.$refs.inputStreet.focus();
        } catch (error) {
          this.$toasted.global.info({
            message: "CEP não encontrado. Favor preencha seu endereço.",
          });
          Object.assign(this.value, {
            city: "",
            neighborhood: "",
            state: "",
            street: "",
            country: "",
          });
        } finally {
          this.loadingZipcode = false;
        }
      }
    },
  },
};
</script>

<style scoped></style>
